import React from 'react';
import c from 'classnames';

import Container from 'components/ui/Container';
import { useI18next } from 'gatsby-plugin-react-i18next';
import LawCard from '../Card';
import Headline from '../Headline';
import CopyText from '../CopyText';
import Button from '../ui/Button';
import { Link } from 'gatsby';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';

const laws = [
  'fieldLawCivil',
  'fieldLawBusiness',
  'fieldLawFamily',
  'fieldLawCP',
  'fieldLawLabour',
  'fieldLawIP',
  'fieldLawCriminal',
  'fieldLawWhatLaw',
];

const LawCopy = ({ children, front }: any) => (
  <CopyText variant="copy-2" color="black" additionalClass={front ? 'mb-4 text-center' : 'mb-4 text-left'}>
    {children}
  </CopyText>
);

const lawIcons = [
  'LawCivil',
  'LawBusiness',
  'LawFamily',
  'LawConsumer',
  'LawLabour',
  'LawIntellectual',
  'LawCriminal',
  'LawWhatLaw',
];

const getLawFrontDescription = (title: string, description?: string, descriptionTwo?: string) => (
  <div className="flex flex-col w-full">
    <CopyText
      variant="copy-1"
      style={{ color: '#302e81' }}
      additionalClass={c('mb-8 uppercase text-center', !description && 'mb-36')}
    >
      {title}
    </CopyText>
    {description && <LawCopy front>{description}</LawCopy>}
    {descriptionTwo && <LawCopy front>{descriptionTwo}</LawCopy>}
  </div>
);

const getLawBackDescription = (parts: any[], link: string, toContact?: boolean) => {
  const { t } = useI18next();

  return (
    <div className="flex flex-col w-full h-full">
      {parts}
      <Link to={link} className="self-center mx-auto mt-4 mb-8 max-w-6xs w-full mt-auto">
        <Button className="w-full" style={{ border: '1px solid #e45a11' }}>
          <CopyText variant="copy-1">
            <span style={{ color: '#e45a11' }}>{t(toContact ? 'toContact' : 'details')}</span>
          </CopyText>
        </Button>
      </Link>
    </div>
  );
};

const Fields: React.FC = () => {
  const { t } = useI18next();
  const frontDescriptions = {
    fieldLawCivil: getLawFrontDescription(t('fieldLawCivil'), t('fieldLawCivilDescription')),
    fieldLawBusiness: getLawFrontDescription(t('fieldLawBusiness'), t('fieldLawBusinessDescription')),
    fieldLawFamily: getLawFrontDescription(t('fieldLawFamily'), t('fieldLawFamilyDescription')),
    fieldLawCP: getLawFrontDescription(t('fieldLawCP'), t('fieldLawCPDescription')),
    fieldLawLabour: getLawFrontDescription(t('fieldLawLabour'), t('fieldLawLabourDescription')),
    fieldLawIP: getLawFrontDescription(t('fieldLawIP'), t('fieldLawIPDescription')),
    fieldLawCriminal: getLawFrontDescription(
      t('fieldLawCriminal'),
      t('fieldLawCriminalDescription'),
      t('fieldLawCriminalDescriptionOne')
    ),
    fieldLawWhatLaw: getLawFrontDescription(t('fieldLawWhatLaw')),
  };

  const backDescriptions = {
    fieldLawCivil: getLawBackDescription(
      [
        <LawCopy key="fieldLawCivilBackDescriptionOne">{t('fieldLawCivilBackDescriptionOne')}</LawCopy>,
        <LawCopy key="fieldLawCivilBackDescriptionTwo">{t('fieldLawCivilBackDescriptionTwo')}</LawCopy>,
        <LawCopy key="fieldLawCivilBackDescriptionThree">{t('fieldLawCivilBackDescriptionThree')}</LawCopy>,
        <LawCopy key="fieldLawCivilBackDescriptionFour">{t('fieldLawCivilBackDescriptionFour')}</LawCopy>,
      ],
      '/law-civil'
    ),
    fieldLawBusiness: getLawBackDescription(
      [
        <LawCopy key="fieldLawBusinessBackDescriptionOne">{t('fieldLawBusinessBackDescriptionOne')}</LawCopy>,
        <LawCopy key="fieldLawBusinessBackDescriptionTwo">{t('fieldLawBusinessBackDescriptionTwo')}</LawCopy>,
        <LawCopy key="fieldLawBusinessBackDescriptionThree">{t('fieldLawBusinessBackDescriptionThree')}</LawCopy>,
      ],
      '/law-business'
    ),
    fieldLawFamily: getLawBackDescription(
      [
        <LawCopy key="fieldLawFamilyBackDescriptionOne">{t('fieldLawFamilyBackDescriptionOne')}</LawCopy>,
        <LawCopy key="fieldLawFamilyBackDescriptionTwo">{t('fieldLawFamilyBackDescriptionTwo')}</LawCopy>,
        <LawCopy key="fieldLawFamilyBackDescriptionThree">{t('fieldLawFamilyBackDescriptionThree')}</LawCopy>,
      ],
      '/law-family'
    ),
    fieldLawCP: getLawBackDescription(
      [<LawCopy key="fieldLawCPBackDescriptionOne">{t('fieldLawCPBackDescriptionOne')}</LawCopy>],
      '/law-consumer-protection'
    ),
    fieldLawLabour: getLawBackDescription(
      [
        <LawCopy key="fieldLawLabourBackDescriptionOne">{t('fieldLawLabourBackDescriptionOne')}</LawCopy>,
        <LawCopy key="fieldLawLabourBackDescriptionTwo">{t('fieldLawLabourBackDescriptionTwo')}</LawCopy>,
        <LawCopy key="fieldLawLabourBackDescriptionThree">{t('fieldLawLabourBackDescriptionThree')}</LawCopy>,
      ],
      '/law-labour'
    ),
    fieldLawIP: getLawBackDescription(
      [<LawCopy key="fieldLawIPBackDescription">{t('fieldLawIPBackDescription')}</LawCopy>],
      '/law-intellectual-property'
    ),
    fieldLawCriminal: getLawBackDescription(
      [<LawCopy key="fieldLawCriminalDescriptionBack">{t('fieldLawCriminalDescriptionBack')}</LawCopy>],
      '/law-criminal'
    ),
    fieldLawWhatLaw: getLawBackDescription(
      [<LawCopy key="fieldLawWhatLawBackDescriptionOne">{t('fieldLawWhatLawBackDescriptionOne')}</LawCopy>],
      '/contact',
      true
    ),
  };

  return (
    <Container section>
      <HorizontalTitleSeparator title={t('fieldsTitle')} additionalClass="mt-16 uppercase" />
      <Headline variant="headline-5" additionalClass="mt-8 mb-32 w-full text-center">
        {t('fieldsIntro')}
      </Headline>
      <Headline variant="headline-6" additionalClass="mt-8 mb-32 w-full">
        {t('fieldsOne')}
      </Headline>
      <div className="grid grid-cols-2 gap-48 justify-center items-center w-full mb-40">
        {laws.map((law, index) => (
          <LawCard
            key={law}
            law={law}
            // @ts-ignore
            backDescription={backDescriptions[law]}
            // @ts-ignore
            frontDescription={frontDescriptions[law]}
            icon={lawIcons[index]}
          />
        ))}
      </div>
    </Container>
  );
};

export default Fields;
