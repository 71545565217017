import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Fields from 'components/Fields';
import { graphql } from 'gatsby';

const FieldsPage: React.FC = () => (
  <Layout id="fields">
    <SEO title="Fields" />
      <Fields />
  </Layout>
);

export default FieldsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
