import React from 'react';

import * as classes from './Card.module.scss';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Icon from '../Icon';
import CopyText from '../CopyText';

type Props = {
  icon: any;
  law: string;
  frontDescription: any;
  backDescription: any;
};

function LawCard({ icon, law, frontDescription, backDescription }: Props) {
  const { t } = useI18next();

  return (
    <div className={classes.lawContainer}>
      <div className={classes.law}>
        <div className={classes.lawFront}>
          <div>
            <Icon name={icon} additionalClass={classes.icon} />
          </div>
          <CopyText variant="copy-1" additionalClass="mb-10 w-full text-center px-20 text-black" >
            {frontDescription}
          </CopyText>
        </div>
        <div className={classes.lawBack}>
          <div className="flex flex-col w-full h-full">
            <div className={classes.lawBackName}>{t(law)}</div>
            <div className={classes.lawBackDescription}>{backDescription}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LawCard;
